<template>
  <div class="center">
    <AppDropdownSelect
      :options="options"
      :autoHide=true
      :showArrow=false
      :selectable=false
      placement="bottom"
      popoverClass="more-custom-popover"
      @input="onItemClick"

    >
      <template slot="trigger">
        <span class="more-icon"><i class="fa fa-ellipsis-v"></i></span>
      </template>
    </AppDropdownSelect>
  </div>
</template>
<script>
import AppDropdownSelect from '@/components/common/app-dropdown-select/AppDropdownSelect'

export default {
  components: {
    AppDropdownSelect
  },

  props: ['row', 'nested', 'xprops'],

  data () {
    return {
      options: [
        { icon: 'fa fa-eye btn-icon-wrapper', label: 'Show', value: 'show' },
        { icon: 'fa fa-edit', label: 'Edit', value: 'edit' },
        { icon: 'fa fa-list-alt', label: 'Embedded Forms', value: 'forms' },
        { icon: 'fa fa-sign-out', label: 'Release Number', value: 'release' },
        { icon: 'fa fa-trash', label: 'Delete', value: 'delete' },
      ],
    }
  },

  methods: {
    openDeleteModal () {
      this.xprops.eventbus.$emit('openDeleteModal', this.row);
    },

    openShowHTMLCodeModal () {
      this.xprops.eventbus.$emit('openShowHTMLCodeModal', this.row)
    },

    onItemClick (item) {
      switch (item.value) {
        case 'edit':
          this.$router.push({ name: 'business.installs.edit', params: { id: this.row.id } })
          break;
        case 'forms':
          this.$router.push({ name: 'business.embedded-forms.index', params: { install: this.row.id } })
          break;
        case 'show':
          this.xprops.eventbus.$emit('openShowHTMLCodeModal', this.row)
          break;
        case 'release':
          this.xprops.eventbus.$emit('openReleaseModal', this.row);
          break;
        case 'delete':
          this.xprops.eventbus.$emit('openDeleteModal', this.row);
          break;
      }
    },
  },
}
</script>

<style scoped lang="scss">
  button {
    i {
    }
  }
</style>